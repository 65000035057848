import React from 'react';
import '../components/css/home.css'; // Import the CSS file for styles

const Home = () => {
    const handleJoinWaitlist = () => {
        // window.open('https://t.me/Minemint_official_bot?start', '_blank');
        // window.open('https://t.me/Minemint_mine_bot/mint?start?start', '_blank');
        window.open('https://t.me/nftminemint_bot/minemmintnft?start', '_blank');
      };
    
      const handleExploreMore = () => {
        window.location.href = '/research'; // This would navigate to the '/explore' page on the same site
      }; 


  return (
    <div>
    <div className="home-container">
      {/* Hero Section */}
      <header className="hero">
        <h1>Welcome to MineMint</h1>
        <p>Discover the world of NFT mining on the TON Blockchain with MineMint.</p>
        <h3>The innovative NFT mining platform bringing blockchain enthusiasts together.</h3>

        {/* Call-to-Action Buttons */}
        <div className="cta-buttons">
          <button className='cta-button' onClick={handleJoinWaitlist}>
            Join The Waitlist
           </button>
          <button className='cta-button cta-explore' onClick={handleExploreMore}>
            Explore More
            </button>
        </div>
      </header>

      {/* Features Section */}
      <section className="features">
        <div className="feature">
          <h3>Seamless NFT Mining</h3>
          <p>Mine exclusive NFTs directly on the TON Blockchain with zero hassle.</p>
        </div>
        <div className="feature">
          <h3>Rewarding Waitlist Program</h3>
          <p>Early participants get exclusive referral bonuses and more!</p>
        </div>
      </section>
      </div>

      {/* Footer with Social Links */}
      <footer className="footer">
        <div className="social-links">
          <a href="https://twitter.com/MineMintvault">Twitter</a>
          <a href="https://t.me/minemint_backup">Telegram</a>
          {/* <a href="https://t.me/MineMint">Telegram</a> */}
        </div>
      </footer>
    {/* </div> */}
    </div>
    
  );
};

export default Home;
