import React from 'react';
import '../components/css/roadmap.css'; // Link to your CSS file for styling

const Roadmap = () => {
  return (
    <div className="roadmap-container">
      <h1>MineMint Roadmap</h1>
      
      <section>
        <h2>Phase 1: Project Setup (✔️)</h2>
        <p>Platform setup completed.</p>
      </section>
      
      <section>
        <h2>Phase 2: Kickstarting/Startup (In Progress)</h2>
        <p>
          - Waitlist form launch. <br />
          - NFT Launching. <br />
          - NFT Minting: PRICE (Range: 0.1 - 0.3 TON). <br />
          - Mining opened.
        </p>
      </section>
      
      <section>
        <h2>Phase 3: Advanced Mining Integrations (Upcoming)</h2>
        <p>
          - Auto-mining feature integration for NFT holders. <br />
          - MMT points transfer integration for NFT holders. <br />
          - Introduction of additional features based on community feedback.
        </p>
      </section>
      
      <section>
        <h2>Phase 4: Launch Preparation (Upcoming)</h2>
        <p>
          - Documentation finalization. <br />
          - Bug fixing and refinement. <br />
          - Marketing and community outreach to build momentum for the official launch.
        </p>
      </section>
      
      <section>
        <h2>Phase 5: Public Launch (Upcoming)</h2>
        <p>
          - TGE & Airdrop Event. <br />
          - Introduction of community governance. <br />
          - Continuous platform improvements and integration of exclusive content and partnerships.
        </p>
      </section>
    </div>
  );
};

export default Roadmap;
