import React from 'react';
import '../components/css/research.css'; // Link to your CSS file for styling


const handleRoadmap = () => {
  window.location.href = '/roadmap'; // This would navigate to the '/explore' page on the same site
}; 

const Research = () => {
  return (
    <div className="research-container">
      <h1>Research on NFT Mining</h1>
      <p>
        Discover the insights of NFT mining and the current trends in blockchain technology.
        MineMint is building on the TON Blockchain, leveraging its scalability and security.
      </p>
      <section>
        <h2>Why TON Blockchain?</h2>
        <p>
          The TON blockchain provides the security, speed, and decentralization that powers
          our platform. Here are key reasons why we chose TON for MineMint.
        </p>
      </section>
      <section>
        <h2>Case Studies</h2>
        <p>
          Learn from other successful blockchain-based NFT projects and how they have shaped
          the future of decentralized mining.
        </p>
      </section>
      <section>
        <h2>Technology Stack</h2>
        <p>
          MineMint leverages the latest in blockchain technology including smart contracts,
          seamless user interfaces, and high-level security measures to ensure a smooth mining
          experience.
        </p>
      </section>
      <section>
        <h2>Learn More about MineMint</h2>
        <a href='https://minemintapps-organization.gitbook.io/whitepaper.minemintapp.fun/introduction'>Read whitepaper</a>
        <p><a onClick={handleRoadmap}>Our Roadmap</a></p>
        <p><a></a></p>
      </section>
    </div>
  );
};

export default Research;
