import React from 'react';
import '../components/css/community.css'; // Link to your CSS file for styling

const Community = () => {
  return (
    <div className="community-container">
      <h1>Join the MineMint Community</h1>
      <p>
        Connect with fellow blockchain and NFT enthusiasts. Stay updated, participate in
        discussions, and become part of the MineMint ecosystem.
      </p>
      <section>
        <h2>Engage with Us</h2>
        <p>
          Follow us on our social media platforms and Telegram channel to get the latest
          updates and participate in discussions.
        </p>
        <ul>
          <li><a href="https://twitter.com/MineMint" target="_blank">Twitter</a></li>
          <li><a href="https://t.me/MineMint" target="_blank">Telegram</a></li>
        </ul>
      </section>
      <section>
        <h2>Ambassador Program</h2>
        <p>Become a MineMint ambassador and help spread the word in your region.</p>
      </section>
      <section>
        <h2>Community Rewards</h2>
        <p>
          Earn referral bonuses and participate in exclusive NFT drops by engaging with the
          MineMint community.
        </p>
      </section>
    </div>
  );
};

export default Community;
