import React from 'react';
import '../components/css/tokenomics.css'; // Link to your CSS file for styling

const Tokenomics = () => {
  return (
    <div className="tokenomics-container">
      <h1>MineMint Tokenomics</h1>
      <section>
        <h2>Total Token Supply</h2>
        <p>MineMint has a total supply of 1 billion tokens, distributed as follows:</p>
        <ul>
          <li>60% - Community Mining Rewards</li>
          <li>10% - Team</li>
          <li>10% - Partnerships and Marketing</li>
          <li>20% - Ecosystem Fund and Liquidity</li>
        </ul>
      </section>
      <section>
        <h2>Mining & Earning Model</h2>
        <p>
          Users earn MineMint tokens through mining NFTs, staking, and participating in
          various activities on the platform.
        </p>
      </section>
      <section>
        <h2>Deflationary Mechanisms</h2>
        <p>
          Token burn events and staking mechanisms ensure that MineMint tokens maintain their
          value over time.
        </p>
      </section>
      <section>
        <h2>Base Network Integration</h2>
        <p>
          Our tokens are integrated with the Base network, ensuring interoperability and
          scalability within the ecosystem.
        </p>
      </section>
    </div>
  );
};

export default Tokenomics;
